import {defineStore} from 'pinia'
import {useLocaleStore} from "~/stores/locale";
import {PublicApi} from '~/gen/openapi/portalService';

interface State {
    data: Record<string, string>,
    dataTimestamp: any,
    error: string,
    errorMessage: string,
    isLoading: boolean,
}

export const useHelpTextsStore = defineStore('helpTexts', {
    state: (): State => ({
        data: {},
        dataTimestamp: null,
        error: '',
        errorMessage: '',
        isLoading: false,
    }), getters: {
        helpTexts(state) {
            const localeStore = useLocaleStore()
            const languageCode = (localeStore.locale) || 'no'

            // @ts-ignore
            return state.data && languageCode in state.data ? state.data[languageCode] : null
        },
    }, actions: {
        fetch() {
            const cacheTimeout = 5000 // 5 seconds (5s * 1000ms)

            if (!this.dataTimestamp || new Date(this.dataTimestamp).getTime() + cacheTimeout < new Date().getTime()) {
                this.dataTimestamp = new Date().toJSON()
                this.isLoading = true

                const {$config, $axios} = useNuxtApp()
                const { setLocaleMessage, messages } = useI18n()
                const publicApi = new PublicApi(undefined, $config.public.apiBaseHost, $axios)

                return new Promise((resolve, reject) => {
                    publicApi.publicGetHelpTexts()
                        .then(response => response.data)
                        .then((response: any) => {
                            this.data = response
                            this.isLoading = false

                            Object.keys(response).forEach((key) => {
                                if (response[key] != null) {
                                    const languageResponse = response[key]
                                    const filteredTranslations = {}
                                    const responseKeys = Object.keys(languageResponse)

                                    // Loop through response keys
                                    for (let i = 0; i < responseKeys.length; i++) {
                                        const translationKey = responseKeys[i];
                                        const existingTranslation = messages.value[key] && messages.value[key][translationKey];

                                        // Add translation only if it doesn't already exist
                                        if (languageResponse[translationKey] && !existingTranslation) {
                                            // @ts-ignore
                                            filteredTranslations[translationKey] = languageResponse[translationKey];
                                        }
                                    }

                                    // Merge filtered translations with existing messages
                                    setLocaleMessage(key, Object.assign({}, messages.value[key], filteredTranslations));
                                }
                            });

                            resolve(response);

                        })
                        .catch((error) => {
                            this.isLoading = false
                            this.error = 'ERROR_HELP_TEXTS_UNABLE_TO_LOAD'
                            this.errorMessage = `${error.response && error.response.status} ${error.response && error.response.statusText}`

                            reject(error)
                        })
                })
            }

            return Promise.resolve(null)
        },
    }
})
